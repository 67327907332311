/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "labyrinthitis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#labyrinthitis",
    "aria-label": "labyrinthitis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Labyrinthitis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-impaired-phone-hero.png",
    alt: "Soundwaves and ear background hero image",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "clinical-picture-diagnosis--treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#clinical-picture-diagnosis--treatment",
    "aria-label": "clinical picture diagnosis  treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Clinical picture, diagnosis & treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Definition"), ": Labyrinthitis is an inner ear disorder. It occurs when a canal in the inner ear known as the Labyrinth becomes inflamed. The labyrinth is the innermost part of the ear and is made up of the cochlea and the vestibular system. The cochlea is a spiral-shaped cavity that plays a central role in sound interactions between your ear and your brain. The vestibular system is made up of channels that are filled with fluid. These channels are vital in keeping your sense of balance. Your doctor may also describe the problem as vestibular neuritis, which is a similar syndrome but with additional hearing symptoms. However, in both cases, treatment options are the same."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-symptoms",
    "aria-label": "what are the symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the symptoms?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the ", React.createElement(_components.strong, null, "primary symptoms of labyrinthitis (or vestibular neuritis) is a sense of ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/bppv/",
    className: "c-md-a"
  }, "vertigo"), "."), " It may be important here to distinguish between feeling dizzy and experiencing vertigo. While dizziness may be unpleasant and even cause the ", React.createElement(_components.strong, null, "feeling of having nausea"), " (an early indicator that labyrinthitis is present), the two symptoms are very different. Dizziness is a feeling of unsteadiness or light-headedness. On the other hand, vertigo is the feeling that either you or your surroundings are spinning, making it hard for you to walk or even keep your balance at times. It is important to note that vertigo may be caused by many other issues other than labyrinthitis. However, ", React.createElement(_components.strong, null, "vertigo is the most common symptom that people with labyrinthitis experience.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Other common symptoms of labyrinthitis include nausea, illness, ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "hearing loss"), ", and ringing in the ear (tinnitus). Mild headaches and ear pain may also occur. You may also find that fluid or even pus is leaking from your ear cavity. In certain cases, sufferers may ", React.createElement(_components.strong, null, "also experience changes to their vision"), ". These changes may appear as blurred vision or even double vision."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The symptoms of labyrinthitis are usually ", React.createElement(_components.strong, null, "quite severe during the first week"), ", and it will be unlikely that you will be able to carry on normal day-to-day activities without effort and discomfort. The intense feeling of vertigo often diminishes or even vanishes entirely after the first days. However, you may experience sudden and brief recurrences over the next month if you move your head suddenly or in a certain way."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In most cases of labyrinthitis, symptoms will go away after a few weeks. But this process may take longer depending on the cause and severity of the inflammation. In extreme cases, labyrinthitis can even result in some permanent loss of hearing. While these cases are rare, it is important to always see your doctor if you suspect you may be experiencing this syndrome."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-causes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-causes",
    "aria-label": "what are the causes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the causes?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inflammation of the labyrinthitis or vestibular nerve ", React.createElement(_components.strong, null, "causes the nerve to send confusing signals to the brain"), ", creating the false impression that the body is moving. This feeling conflicts with feedback from your other senses and causes issues with your sense of balance while creating the impression that the room is spinning."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While the direct casual chain for the development of labyrinthitis is still a matter of medical debate, ", React.createElement(_components.strong, null, "it usually follows a viral infection"), ", such as a cold or flu. That said, it can also follow on the heels of a bacterial infection. There are a variety of contributing factors that can increase the likelihood of falling victim to this problem. While labyrinthitis is more common in older people because of their susceptibility to infections, it can occur at any age. Factors contributing to the problem include respiratory illnesses, such as bronchitis, stomach or herpes viruses, and inner ear viral infections. It is also linked to infectious organisms such as the ones responsible for the development of Lyme disease."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You are more ", React.createElement(_components.strong, null, "at risk of developing labyrinthitis if you smoke or drink large quantities of alcohol"), ". You may also be more prone to this issue if you take prescription medication or have a history of allergies. Labyrinthitis has also been linked to stress, and if you are constantly experiencing fatigue, this may play a contributing role in developing the syndrome. This is one reason why it is not recommended to attempt to be overly active in the first week following the development of labyrinthitis."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-the-treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-the-treatment",
    "aria-label": "what is the treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is the treatment?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In most cases, ", React.createElement(_components.strong, null, "labyrinthitis will resolve itself with the right amount of fluid intake, rest, and medication"), " to combat the more severe symptoms. These may include antihistamines, antiemetics, and sedatives that can help control the nausea and vomiting brought about by vertigo. However, these may prolong the length of time it takes for the vertigo to pass completely, which is why it is recommended to use such methods only in extreme cases of the symptom."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Direct medication for labyrinthitis treatment is rarely required"), ". However, if your labyrinthitis is the result of a bacterial infection, your doctor may prescribe antibiotics as a treatment. He or she may also recommend a base level of activity during your recuperation, such as simple balancing exercises.\nIn very rare cases, symptoms may take several months or even years to go away. These unique instances usually need a special type of treatment known as vestibular rehabilitation therapy. You should always contact your doctor if your symptoms have not gotten better after three weeks."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Labyrinthitis or vestibular neuritis affects an estimated 35 million people every year"), ". It affects both adults and children; however, the majority of cases are concentrated to ages between 40-50 years. Viral labyrinthitis is by far the most common version of the problem. While the symptoms of labyrinthitis can be very worrying during their initial onset, it is best to keep a calm attitude and seek medical advice if symptoms do not get better quickly. Also, while the first stages can make it difficult to think clearly, it is recommended that you keep track of the timing of the vertigo and the triggers, as this can aid your doctor greatly in making the correct diagnosis."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
